import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { OptionColumns } from 'src/app/components/forms/ajax-autocomplete-chips/ajax-autocomplete-chips.component';
import { DataService } from 'src/app/services/data.service';
import { FileServer } from 'src/app/services/fileserver.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'import-dialog',
  templateUrl: './import-dialog.component.html',
  styleUrls: ['./import-dialog.component.scss']
})
export class ImportDialog {
  public form: FormGroup;
  public ready = false;
  public btn_save_disabled = true;
  public btn_save_loader = false;
  protected server_errors = null;
  public server_error = null;
  public import_errors = null;
  public mailinglistsOptionsColumns: OptionColumns = {
    id: 'id',
    label: 'name'
  };

  public downloadImportModelLoader: boolean = false;
  public import_not_done = null;
  public import_bt_clicked: boolean = false;
  public nb_records_added = null;
  public showNbRecordsAdded: boolean = false;
  public importDone: boolean = false;
  public no_leads_folder_error = null;
  public import_mode = null;
  public exportModelUrl: string = null;
  public importUrl: string = null;
  public exportModelParams = {};
  public importType: string = null;

  constructor(
    public dialogRef: MatDialogRef<ImportDialog>,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data, private dataService: DataService,
    public fs: FileServer) {

    // create formgroup
    this.form = new FormGroup({
      sendEmail: new FormControl(null,[]),
      file: new FormControl(null,[Validators.required]),
      create_mode: new FormControl(null,[]),
      update_mode: new FormControl(null,[]),
    }, {
      updateOn: 'change',
    });

    if(data) {
      if(data.exportModelUrl) {
        this.exportModelUrl = data.exportModelUrl;
      }
      if(data.importUrl) {
        this.importUrl = data.importUrl;
      }
      if(data.exportModelParams) {
        this.exportModelParams = data.exportModelParams;
      }
      if(data.importType) {
        this.importType = data.importType;
      }
    }
  }

  ngOnInit() {
    let proms = [];
    Promise.all(proms).then(rez => {
      this.btn_save_disabled = false;
      this.ready = true;
    }).catch(err => {
      console.log(err);
    })

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  closeDialog() {
    if(this.import_bt_clicked) {
      this.dialogRef.close({reload: true});
    } else {
      this.dialogRef.close('bt_no');
    }
  }

  setData() {
    const formData: FormData = new FormData();
    let data = this.form.value;
    if(data.sendEmail == null) {
      delete data.sendEmail;
    }
    if(data.create_mode == null) {
      delete data.create_mode;
    }
    if(data.update_mode == null) {
      delete data.update_mode;
    }
    if(!data.create_mode && !data.update_mode) {
      data['import_mode'] = 'file_verification';
    } else if(data.create_mode && data.update_mode) {
      data['import_mode'] = 'update_or_create';
    } else if(data.create_mode) {
      data['import_mode'] = 'create';
    } else if(data.update_mode) {
      data['import_mode'] = 'update';
    }
    data['import_type'] = this.importType;
    for (let i in data) {
      if (typeof data[i] === 'boolean') {
        formData.append(i, data[i] ? '1' : '0');
      } else if (data[i] instanceof File) {
        formData.append(i, data[i], data[i].name)
      } else if (typeof data[i] === 'object') {
        formData.append(i, JSON.stringify(data[i]));
      } else { 
        formData.append(i, data[i])
      }
    }
    return formData;
  }

  import() {
    if(!this.importUrl) {
      return;
    }
    this.server_error = null;
    this.import_errors = null;
    this.reValidateForms();
    this.btn_save_disabled = true;
    this.btn_save_loader = true;
    this.showNbRecordsAdded = false;
    this.no_leads_folder_error = null;
    this.import_mode = null;

    let data = this.setData();
    let params = data;
    if(this.form.valid) {
      this.import_bt_clicked = true;
      this.dataService.postAsPromise(this.importUrl, params).then(res => {
        this.btn_save_disabled = false;
        this.btn_save_loader = false;

        if(res.errors && res.errors.length) {
          this.import_errors = res.errors;
        } else {
          this.import_errors = [];
          // this.dialogRef.close('bt_save');
        }
        this.nb_records_added = res.nb_records_added;
        this.showNbRecordsAdded = true;
        this.import_mode = res.import_mode;
        this.importDone = true;
      }).catch(err => {
        this.btn_save_disabled = false;
        this.btn_save_loader = false;
        this.server_error = err.message;
        if(err && err.message == 'no_leads_folder') {
          this.no_leads_folder_error = true;
        }
      })
    } else {
      console.log("Form not valid!");
      this.btn_save_disabled = false;
      this.btn_save_loader = false;
    }
  }

  hasError(controlName: string, errorName: string) {
    if (!controlName) {
      return this.form.hasError(errorName);
    }
    return this.form.controls[controlName].hasError(errorName);
  }

  reValidateForms() {
    this.form.updateValueAndValidity({ onlySelf: true, emitEvent: false });
    for (let f in this.form.controls) {
      this.form.controls[f].markAsTouched();
      this.form.controls[f].updateValueAndValidity({ emitEvent: false });
    }
  }

  downloadExportModel() {
    if(!this.exportModelUrl) {
      return;
    }
    this.downloadImportModelLoader = true;
    let path = environment.api.root + this.exportModelUrl;
    this.fs.downloadFile(path, this.exportModelParams).then(res => {
      this.downloadImportModelLoader = false;
    }).catch(error => {
      this.downloadImportModelLoader = false;
    });
  }
}
