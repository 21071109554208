import { EditUserDialog } from '../components/dialogs/userdialog/user-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Injectable } from "@angular/core";
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from './authentication.service';
import { DataService } from './data.service';


@Injectable({
  providedIn: "root"
})
export class NavigationService {
  public pageTitle = '';

  protected main_menu_auth = [
    { type: "item", label: "STATIC.NAV.HOME", path: "/dashboard", icon: "home", roles: ['super-admin','partner','partner_as_client'] },
    { type: "separator" },
    {
      type: "group", label: "STATIC.NAV.CMS.CMS", icon: "list", roles: ['super-admin'],
      items: [
        { type: "item", label: "STATIC.NAV.CMS.CONTENTS", path: "/cms/contents", icon: "list", roles: ['super-admin'] },
        { type: "item", label: "STATIC.NAV.CMS.NAVIGATIONS", path: "/cms/navigations", icon: "list", roles: ['super-admin'] },
        { type: "item", label: "STATIC.NAV.CMS.EVENTS", path: "/cms/events", icon: "list", roles: ['super-admin'] },
        { type: "item", label: "STATIC.NAV.CMS.MEDIA", path: "/cms/media", icon: "list", roles: ['super-admin'] },
        { type: "item", label: "STATIC.NAV.CMS.NOTIFICATIONS", path: "/cms/notifications", icon: "list", roles: ['super-admin'] },
      ]
    },
    { type: "separator" },
    { type: "item", label: "STATIC.NAV.QUESTIONS",path:"/questions", icon: "question_mark", roles: ['super-admin','partner','partner_as_client'],},    
    { type: "separator" },
    { type: "item", label: "STATIC.NAV.USERS", path: "/users", icon: "people", roles: ['super-admin','partner','partner_as_client'] },
    { type: "separator" },
   
    // {
    //   type: "group", label: "STATIC.NAV.CUSTOMERS", icon: "business_center", roles: ['super-admin','partner','partner_as_client'],
    //   items: [
    //     { type: "item", label: "STATIC.NAV.CLIENTS", path: "/clients", icon: "work_outline", roles: ['super-admin','partner'] },
    //     { type: "item", label: "STATIC.NAV.USERS", path: "/users", icon: "people", roles: ['super-admin','partner','partner_as_client'] },
    //   ]
    // },


    {
      type: "group", label: "STATIC.NAV.SETTINGS", icon: "settings", roles: ['super-admin'],
      items: [
        { type: "item", label: "STATIC.NAV.PARAMETERS", path: "/parameters", icon: "settings", roles:['super-admin'] },
        { type: "item", label: "STATIC.NAV.LISTS", path: "/lists", icon: "checklist", roles:['super-admin'] },
       // { type: "item", label: "STATIC.NAV.HELPMESSAGES", path: "/help", icon: "help_outline", roles:['super-admin'] },
      ]
    }
  ];

  protected main_menu = [
  ];


  protected profile_menu_auth = [
    { type: "item", label: "STATIC.NAV.PROFILE", icon: "account_circle", click: () => { this.showUserDialog() } },
  ];
  protected profile_menu = [
    //  { type: "item", label: "NAV.LOGOUT", path: "/logout", icon: "exit_to_app" },
  ];

  constructor(
    public auth: AuthenticationService,
    public translate: TranslateService,
    public dialog: MatDialog,
    private dataService : DataService
  ) {
    const self = this;

  }

  getMenu(menu) {
    let user = this.auth.getCurrentAuthUser();

    switch (menu) {
      case 'main':
        return user && user.exists() ? this.main_menu_auth : this.main_menu;
        break
      case 'profile':
        return user && user.exists() ? this.profile_menu_auth : this.profile_menu;
        break

    }
  }

  logout() {
    this.auth.logout();
  }

  setTitle(title, subtitle = '') {
    if (title) {
      this.pageTitle = this.translate.instant(title);
      if (subtitle) {
        this.pageTitle += ' : ' + subtitle;
      }
    } else {
      this.pageTitle = '';
    }
  }

  getPageTitle() {
    return this.pageTitle;
  }

  showUserDialog() {
    let title = this.translate.instant('USER.MY_PROFILE');
    let user = this.auth.getCurrentUser();
    console.log("user = ", user)
    const dialogRef = this.dialog.open(EditUserDialog, {
      width: '370px',
      autoFocus: false,
      disableClose: true,
      data: {
        title: title,
        item: user,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.auth.loadCurrentUser();
    });
  }

  linkedinLogin() {
    this.dataService.getAsPromise('linkedin/login')
      .then(res=>{
        console.log('LinkedIn login endpoint',res)
        location.href=res.url;
      })
  }

  linkedinAppLogin() {
    this.dataService.getAsPromise('linkedin/applogin')
      .then(res=>{
        console.log('LinkedIn app login',res)
        
      })
  }
}
