import { Component, EventEmitter, ElementRef, ViewChild, Input, HostBinding, Optional, Self, forwardRef, OnInit, DoCheck, Output } from '@angular/core';
import { UntypedFormControl, NgControl, UntypedFormBuilder } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Observable, Subject } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';
import * as _ from 'lodash';
import { MatFormFieldControl } from '@angular/material/form-field';
import { FocusMonitor } from '@angular/cdk/a11y';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import * as countriesInfo from 'countries-information';


/**
 * @title Country Autocomplete
 */
@Component({
    selector: 'autocomplete-country-field',
    templateUrl: './autocomplete-country-field.component.html',
    styleUrls: ['./autocomplete-country-field.component.scss'],
    providers: [
        { provide: MatFormFieldControl, useExisting: AutocompleteCountryFieldComponent },
    ],

})
export class AutocompleteCountryFieldComponent
    implements
    MatFormFieldControl<any[]>,
    ControlValueAccessor,
    OnInit,
    DoCheck {

    public firstInit = false;
    stateChanges = new Subject<void>();
    static nextId = 0;
    private _placeholder: string;
    focused = false;
    errorState = true;
    controlType = 'autocomplete-field';

    fieldCtrl = new UntypedFormControl();
    filteredList: any[];
    arrayFilteredList = [];
    selectedItem = null;

    _value: any = null;
    list = null;
    _populated = false;
        
    @Input()
    multiple: boolean = true;
    @Input()
    showIso: boolean = false;
    @Input()
    color: string = null;
    @Input()
    editable: boolean = true;
    @Input()
    selectable: boolean = true;
    @Input()
    removable: boolean = true;
    @Input()
    readonly: boolean = false;
    @Input()
    visible: boolean = true;
    @Input()
    // addOnBlur: boolean = true;
    @Input()
    get placeholder() {
        return this._placeholder;
    }
    set placeholder(plh) {
        this._placeholder = plh;
        this.stateChanges.next();
    }

    @Input()
    get value(): any | null {
        return this._value;
    }
    set value(val: any | null) {
        this.initValue(val);
        this.stateChanges.next();
        this.onChange(val);
        this.onTouch();
    }

    @Input()
    get required() {
        return this._required;
    }
    set required(req) {
        
        this._required = coerceBooleanProperty(req);
        this.stateChanges.next();
    }
    private _required = false;

    get empty() {
        return !this._value || this._value.length == 0;
    }
    @Input()

    get disabled(): boolean { return this._disabled; }
    set disabled(value: boolean) {
        this._disabled = coerceBooleanProperty(value);
        this._disabled ? this.fieldCtrl.disable() : this.fieldCtrl.enable();
        this.stateChanges.next();
    }
    private _disabled = false;

    @HostBinding() id = `autocomplete-chips-${AutocompleteCountryFieldComponent.nextId++}`;

    @HostBinding('class.floating')
    get shouldLabelFloat() {
        return this.focused || !this.empty;
    }

    @HostBinding('attr.aria-describedby') describedBy = '';

    setDescribedByIds(ids: string[]) {
        this.describedBy = ids.join(' ');
    }

    @Input() click_event;
    @Input() searchTimeout = 300; //milliseconds
    @Input() showFlag = false;

    @ViewChild('countryField', { static: false }) countryField: ElementRef<HTMLInputElement>;
    @ViewChild('auto', { static: false }) matAutocomplete: MatAutocomplete;
    @ViewChild('auto', { static: false, read: MatAutocompleteTrigger }) matAutocompleteTrigger: MatAutocompleteTrigger;
    constructor(
        @Optional() @Self() public ngControl: NgControl,
        fb: UntypedFormBuilder,
        private fm: FocusMonitor,
        private elRef: ElementRef<HTMLElement>
    ) {

        fm.monitor(elRef.nativeElement, true).subscribe(origin => {
            // mark the input as touched just after the input was focused
            if(this.focused) {
                this.onTouch();
            }
            
            this.focused = !!origin;
            this.stateChanges.next();
        });

        if (this.ngControl != null) {
            // Setting the value accessor directly (instead of using
            // the providers) to avoid running into a circular import.
            this.ngControl.valueAccessor = this;
        }


        // load countries
        let countries = countriesInfo.getAllCountries();
        _.remove(countries, (element) => {
           return  element.alpha2 == 'GB';
        });
        
        this.list = _.orderBy(countries, ['name'],['asc']);

        this.fieldCtrl.valueChanges.pipe(
          startWith(),
          debounceTime(this.searchTimeout),
          map((item: any | null) => {
            if(!item) {
                this.selectedItem = null; 
                this.updateValue();
            }

            if (!this.list) {
                return [];
            }

            let label = item;
            if(item.name && !this.firstInit) {
            label = item.name;
            }
            this.arrayFilteredList = this._filter(label);

            this.firstInit =false;
            return this._filter(label);
          }
        )
        
        ).subscribe(items => {
            if(items) {
                this.filteredList = items;
            }
        });
    }

    ngOnDestroy() {
        this.stateChanges.complete();
        this.fm.stopMonitoring(this.elRef.nativeElement);
    }

    onContainerClick(event: MouseEvent) {
        // if(!this._value) {
        //     this.fieldCtrl.setValue('');
        // } else {
        //     let alpha2Code = this._value;
        //     let country = countriesInfo.getCountryInfoByCode(alpha2Code);
        //     this.fieldCtrl.setValue(country);
        // }
    }

    onChange = (value: any[]) => { };

    onTouch = () => {};

    // Allows Angular to update the model (rating).
    // Update the model and changes needed for the view here.
    writeValue(value: any): void {
        this.initValue(value);
        
        if (this.countryField) {
            this.countryField.nativeElement.blur()
        }
    }

    initValue(value: any): void {
        if (!value) {
            value = null;
        } else if (typeof value == 'string'|| typeof value=='number') {
            value = value;
        } else if (typeof(value.length)=='undefined') {
            value = value;
        }
        this._value = value;
        this.populateSelected();
    }

    // Allows Angular to register a function to call when the model (rating) changes.
    // Save the function as a property to call later here.
    registerOnChange(fn: (value: any[]) => void): void {
        this.onChange = fn;
    }

    // Allows Angular to register a function to call when the input has been touched.
    // Save the function as a property to call later here.
    registerOnTouched(fn: () => void): void {
        this.onTouch = fn;
    }

    setDisabledState(isDisabled: boolean): void {

    }

    ngOnInit() {
        this.firstInit = true;
        if(!this._value) {
            this.fieldCtrl.setValue('');
        } else {
            let alpha2Code = this._value;
            let country = countriesInfo.getCountryInfoByCode(alpha2Code);
            this.fieldCtrl.setValue(country);
        }
     }

    ngDoCheck(): void {
        if (this.ngControl) {
            this.errorState = this.ngControl.invalid && this.ngControl.touched;
            this.stateChanges.next();
        }
    }

    updateValue() {
        // this.writeValue(_.map(this.selectedItems, (o) => o));
        let selectedItem = this.selectedItem ? this.selectedItem : null;
        let selectedValue = this.selectedItem ? this.selectedItem.alpha2 : null;
        this.writeValue(selectedValue);
        this.onChange(this._value);
        // this.onTouch();
    }

    private populateSelected() {
        if (this.list && this._value) {
            this._populated = true;
        }
    }

    @Output() onSelect: EventEmitter<any> = new EventEmitter();
    selected(event: MatAutocompleteSelectedEvent): void {
        this.selectedItem = event.option.value; 
        this.updateValue();
        this.onSelect.emit(this.selectedItem);
    }

    private _filter(label: any): any[] {
        let ret = [];
        if (!label || (label && typeof label != 'string') ) {
            //return this.list.items;
            ret =  _.filter(this.list,  (o)=> {
                return o;
                // return !this._value || this._value.name.indexOf(o.name)<0;
            });
        } else {
            const filterValue = label.toLowerCase();

            ret =  _.filter(this.list, (o)=> {
                return o.name.toLowerCase().indexOf(filterValue) === 0;
            });
        }
        return ret;
    }

    keyPress(event) {
        if (!this.editable) {
            event.preventDefault();
        }
    }

    displayFn(option) {
        if (this.showIso) {
            return option && option.name ? option.name+' - '+option.alpha2 : '';
        } else {
            return option && option.name ? option.name : '';

        }
    }
}
