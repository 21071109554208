import { EventsDialog } from './../dialogs/eventsdialog/events-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { PusherService } from './../../services/pusher.service';
import { AuthUser } from './../../models/AuthUser';
import { User } from './../../models/User';
import { BreakpointObserver } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';
import { Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../../services/authentication.service';
import { Component, NgZone, QueryList, ViewChildren } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { MatExpansionPanel } from '@angular/material/expansion';
import { DataService } from 'src/app/services/data.service';
import { ConfirmDialog } from '../dialogs/confirmdialog/confirm-dialog.component';
import { Router } from '@angular/router';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
  selector: 'app-logged-layout',
  templateUrl: './logged-layout.component.html',
  styleUrls: ['./logged-layout.component.scss']
})
export class LoggedLayoutComponent {
  user : User = null;
  authUser : AuthUser = null;
  sideMenu =[];
  profileMenu = [];
  ready = false;
  nb_notifications = 0;
  public isProd: boolean = false;
  public hideFilter: boolean = false;

  @ViewChildren(MatExpansionPanel) panels: QueryList<MatExpansionPanel>;

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map(result => result.matches));

  constructor(
    private breakpointObserver: BreakpointObserver,
    public translate: TranslateService,
    public auth: AuthenticationService,
    public navService: NavigationService,
    public pusher : PusherService,
    public dialog: MatDialog,
    private dataService: DataService,
    protected router: Router,
    private zone: NgZone,

  ) {

    this.isProd =  environment.production;


    this.auth.currentAuthUser.subscribe(user=>{
      this.authUser = user;
      this.sideMenu = this.navService.getMenu('main');
      this.profileMenu = this.navService.getMenu('profile');
      this.ready = true;
    })
    this.auth.currentUser.subscribe(user=>{
      this.user = user;
    })

    this.pusher.eventsChanged.subscribe(n=>{
      this.nb_notifications = n;
    })

  }

  isItemVisible(item = null) {
    if (item) {
      let hasRole = false;
      let hasPerm = false;
      if (item.roles) {
        hasRole = this.authUser && this.authUser.hasRole(item.roles);
      } else {
        hasRole = true;
      }

      if (item.permission) {
        hasPerm = this.authUser && this.authUser.hasPermission(item.permission);
      } else {
        hasPerm = true;
      }

      return hasPerm && hasRole;
    }
  }

  showEventsDialog() {
    const dialogRef = this.dialog.open(EventsDialog, {
      data: {
        title: this.translate.instant('NOTIFICATIONS.DIALOG_TITLE'),
      },
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.event) {
       this.nb_notifications = this.nb_notifications - 1;
      }

      if(result && result == 'bt_clear'){
        this.pusher.events = [];
        this.nb_notifications = 0;
      }
    });
  }

  minimizeFilter(){
    this.hideFilter = !this.hideFilter;
    if(this.hideFilter){
      this.panels.forEach(panel => {
        panel.close();
      });
    }
  }

  openFilter(){
    if(this.hideFilter){
      this.hideFilter = false;
    }
  }
  
  getLabelTooltip(label){
    return this.hideFilter ? this.translate.instant(label) : null;
  }

  public exitClient() {
    const dialogRef = this.dialog.open(ConfirmDialog, {
      data: {
        title: this.translate.instant('CLIENTS.EXIT_CLIENT_TITLE'),
        text: this.translate.instant('CLIENTS.EXIT_CLIENT_TEXT'),
      },
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == 'bt_yes') {
        //add exit request
        this.dataService
          .getAsPromise(`admin/clients/admin_client/0`)
          .then((res) => {
            this.auth.refreshToken().then(() => {
              setTimeout(() => {
                this.zone.run(() => {
                  this.router.navigate(['/clients']);
                });
              }, 100);
            });
          })
          .catch((err) => {});
      }
    });
  }
  
}
